import React from "react";
import Container from "../../components/Container";
import SEO from "../../components/SEO";
import PageHeader from "../../components/PageHeader";
import MarkdownBody from "../../components/MarkdownBody";
import CareersBanner from "../../components/CareersBanner";

export default () => (
  <>
    <SEO
      description="株式会社SocialDogの情報セキュリティポリシー。"
      title="情報セキュリティポリシー"
    />
    <PageHeader
      title="Information Security Policy"
      lead="情報セキュリティポリシー"
    />
    <Container>
      <MarkdownBody>
        <p>
          株式会社SocialDog（以下、当社）は、当社の情報資産、並びにお客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。
        </p>

        <h3>1. 経営者の責任</h3>
        <ul>
          <li>
            当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。
          </li>
        </ul>

        <h3>2. 社内体制の整備</h3>
        <ul>
          <li>
            当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。
          </li>
        </ul>

        <h3>3. 従業員の取組み</h3>
        <ul>
          <li>
            当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。
          </li>
        </ul>

        <h3>4. 法令及び契約上の要求事項の遵守</h3>
        <ul>
          <li>
            当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。
          </li>
        </ul>

        <h3>5. 違反及び事故への対応</h3>
        <ul>
          <li>
            当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。
          </li>
        </ul>

        <p>
          制定日: 2020年12月2日
          <br />
          2021年9月1日 改定
        </p>
        <p>株式会社SocialDog</p>
      </MarkdownBody>
    </Container>
    <CareersBanner />
  </>
);
